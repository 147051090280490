// Local docs variables
$doc-body-bg:               $black;
$doc-highlight-color:       $gray-100;
$doc-highlight-bg:          $carbon;
$doc-bd-border-color:       $carbon;

$doc-masthead-bg:           $black;

$doc-callout-border-color:  $carbon;
$doc-callout-bg:            $carbon;

$doc-sidebar-color:         $black;
$doc-sidebar-stroke:        "black";
$bd-toc-border-color:       $gray-400;
$bd-toc-color:              $gray-100;

$btn-clipboard-color-hover: $black;
$bd-clipboard-bg-light:     $carbon;

// Extern only
$bd-sidebar-bg:             $carbon;
$bd-links-color:            $gray-100;
$bd-links-color-light:      $gray-300;
$bd-search-bg:              $black;
$doc-sidebar-stroke-ext:    "gray";

$bd-example-modal-bg:       $black;

$bd-purple:        $purple !default;
$bd-purple-bright: lighten(saturate($bd-purple, 5%), 15%) !default;
$bd-purple-light:  lighten(saturate($bd-purple, 5%), 45%) !default;
$bd-dark:          $carbon !default;
$bd-download:      #ffe484 !default;
$bd-info:          $cyan !default;
$bd-warning:       $yellow !default;
$bd-danger:        $orange !default;

$doc-footer-bg:    $carbon;
$doc-footer-color: $body-color;

// Algolia
$algolia-bg:                  $black;
$algolia-hover-bg:            $carbon;
$algolia-title-color:         $gray-100;
$algolia-header-color:        $gray-200;
$algolia-column-color:        $gray-200;
$algolia-text-color:          $gray-100;
$algolia-highlight-color:     $primary;
$algolia-border-color:        $carbon;
